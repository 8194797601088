<template>
  <v-card>
    <v-card-title>
      自由签
    </v-card-title>
    <v-card-subtitle>
      <p>想签什么签什么。</p>
      <p>只需要指定payload字段，默认字段iss, iat, nbf, jti无需指定。exp字段根据TTL和签发时间自动计算。</p>
    </v-card-subtitle>
    <v-card-text>
      <v-form>
        <label>TTL:</label>
        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field v-model="ttl_num_bind" />
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-select
              :items="ttl_unit_selections"
              item-text="name"
              item-value="val"
              v-model="ttl_unit_bind"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              readonly
              v-model="ttl_raw"
              prefix="="
              suffix="ms"
            />
          </v-col>
        </v-row>
        <label>Payload:</label>
        <div class="json-editor-box">
          <vue-json-editor
            v-model="jsonData"
            lang="zh"
            :expandedOnStart="true"
          />
        </div>
        <br />
        <v-btn
          block
          :color="staticConfig.themeConfig.basePrimaryColor"
          @click="doSign"
          dark
        >
          签名
        </v-btn>
        <br />
        <label>签名结果：</label>
        <v-textarea
          readonly
          v-model="signResult"
          height="100"
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>
<style lang="sass">
.jsoneditor-vue
  height: 300px
</style>
<script>
import vueJsonEditor from "vue-json-editor";

export default {
  name: "SP_BasicFreeSign",
  components: {
    vueJsonEditor,
  },
  data: () => ({
    ttl_unit_selections: [
      { name: "毫秒", val: 1 },
      { name: "秒", val: 1000 },
      { name: "分钟", val: 1000 * 60 },
      { name: "小时", val: 1000 * 60 * 60 },
      { name: "天", val: 1000 * 60 * 60 * 24 },
      { name: "月", val: 1000 * 60 * 60 * 24 * 31 },
      { name: "年", val: 1000 * 60 * 60 * 24 * 365 },
    ],
    ttl_unit_rv: 1000 * 60,
    ttl_num_rv: 30,
    signResult: "",
  }),
  computed: {
    jsonData: {
      get() {
        return this.$store.getters.getBasicFreeSignJsonData();
      },
      set(val) {
        this.$store.commit("setBasicFreeSignJsonData", val);
      },
    },
    ttl_raw: {
      get() {
        var ttl = this.$store.getters.getBasicFreeSignTTL();
        this.ttl_num_rv = ttl / this.ttl_unit_rv;
        return ttl;
      },
      set(val) {
        this.$store.commit("setBasicFreeSignTTL", val);
      },
    },
    ttl_unit_bind: {
      get() {
        return this.ttl_unit_rv;
      },
      set(val) {
        this.ttl_unit_rv = val;
        this.ttl_raw = this.ttl_num_rv * this.ttl_unit_rv;
      },
    },
    ttl_num_bind: {
      get() {
        return this.ttl_num_rv;
      },
      set(val) {
        this.ttl_num_rv = val;
        this.ttl_raw = this.ttl_num_rv * this.ttl_unit_rv;
      },
    },
  },
  methods: {
    doSign() {
      var url = "/api/token/free-sign/ttl=/" + this.ttl_raw;
      var jstr = JSON.stringify(this.jsonData);
      var authJwt = this.zllauth.GetJwt();
      if (!authJwt) {
        return;
      }
      if (!authJwt.success) {
        return;
      }
      var pcf = {
        headers: {
          "ZLLAuth-Token": authJwt.token,
          "Content-Type": "application/json",
        },
      };
      //console.log(url, jstr, pcf);
      this.axios
        .post(url, jstr, pcf)
        .then(
          (resp) => {
            if (resp.data.token) {
              this.signResult = resp.data.token;
            } else {
              this.signResult =
                "签名失败：\n（更详细信息可见浏览器控制台输出）\n" +
                JSON.stringify(resp);
              console.warn("Failed do sign", resp);
            }
          },
          (err) => {
            this.signResult =
              "签名失败：\n（更详细信息可见浏览器控制台输出）\n" +
              JSON.stringify(err);
            console.warn("Failed do sign", err);
          }
        )
        .catch((err) => {
          this.signResult =
            "签名失败：\n（更详细信息可见浏览器控制台输出）\n" +
            JSON.stringify(err);
          console.warn("Failed do sign", err);
        });
    },
  },
};
</script>